import { Button, Content, Heading, IllustratedMessage } from '@adobe/react-spectrum';
import { useIsSSR } from '@react-aria/ssr';
import { FocusableRefValue } from '@react-types/shared';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import LoadingState from '../components/LoadingState';

const Custom404: NextPage = () => {
  const isSSR = useIsSSR();
  const router = useRouter();

  const goBackToHomePageLinkRef = useRef<FocusableRefValue<HTMLAnchorElement> | null>(null);

  useEffect(() => {
    if (isSSR) return;

    const listener = (event: MouseEvent) => {
      event.preventDefault();
    };

    const goBackToHomePageLink = goBackToHomePageLinkRef.current!.UNSAFE_getDOMNode()!;

    goBackToHomePageLink.addEventListener('click', listener);

    return () => {
      goBackToHomePageLink.removeEventListener('click', listener);
    };
  }, [isSSR]);

  const handleGoBackToHomePageLinkPress = () => {
    router.push('/');
  };

  return (
    <>
      <Head>
        <title>
          {'Illustration Jobs - Page not found'}
        </title>
      </Head>
      {isSSR ? <LoadingState /> : (
        <IllustratedMessage>
          <NotFound />
          <Heading>
            {'Error 404: Page not found'}
          </Heading>
          <Content marginBottom="size-300">
            {'This page isn\'t available. Try checking the URL or visit a different page.'}
          </Content>
          <Button
            ref={goBackToHomePageLinkRef}
            elementType="a"
            href="/"
            onPress={handleGoBackToHomePageLinkPress}
            variant="secondary"
          >
            {'Go back to home page'}
          </Button>
        </IllustratedMessage>
      )}
    </>
  );
};

export default Custom404;
